import { CurrencyCode } from "~common/generated/admin-graphql";

export function formatPrice(
    value: number, 
    currency: CurrencyCode = CurrencyCode.Usd, 
    isDivide: boolean = true, 
    isLargeNumber: boolean = false,
    showDecimals: boolean = true,
    locale: string = 'en'
  ) {

  const fractionDigits =  showDecimals ? 2 : 0

  const formatted = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: fractionDigits,
  })

  let suffix = ''

  if (isLargeNumber) {    
    if (value >= 1000000) {
      value = value / 1000000
      suffix = 'M'
    } else if (value >= 1000) {
      value = value / 1000
      suffix = 'K'
    }

  }
  const formatData = formatted.format(isDivide ? value / 100 : value)
  return isLargeNumber ? `${formatData}${suffix} `: formatData
}
